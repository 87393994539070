import React from 'react'
import styles from './Inicio.module.css'

export default function Inicio() {
  return (
    <div className='content'>
      <div className={styles.intro}>
        <h1>Psicóloga da Família</h1>
        <span>Abordagem de Integração Sistêmica</span>
        <p>CRP 12/24458</p>
        <h2>Ivone Carmen Paim</h2>
      </div>
    </div>
  )
}
