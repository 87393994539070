import React from 'react'
import styles from './Rodape.module.css'
import { Link, useLocation } from 'react-router-dom'
import Button from 'components/Button';

import ivone from './ivone.png'
import whats from './whatsapp.svg'

export default function Rodape() {
  let location = useLocation().pathname;

  return (
    <>
    <div className={styles.content_wrap}></div>
    <footer className={styles.footer_inicio} >
      <div className={styles.footer_content}>
        <div className={styles.botao}>
          <Link to="https://wa.me/5549999372135?text=Gostaria+de+agendar+uma+consulta" target="_blank">
            <Button>Agendar Consulta</Button>
          </Link>
          <p>Marque sua primeira Consulta</p>
        </div>
        <div className={styles.contato}>
          <img className={styles.simbolo} src="./psicologia-icon.svg" alt="Simbolo da Psicologia" />
          <div className={styles.dados}>
            <div className={styles.whats}>
              <img src={whats} alt="Icone de Whatsapp" />
              <h2>tel: 49 9 9937 2135</h2>
            </div>
            <p className={styles.text_space}>atendimento</p>
            <p className={styles.text_destaque}>Adulto | Infantil</p>
          </div>
          <div className={location !== '/'? styles.assinatura : styles.none}>
            <h1>Psicóloga da Família</h1>
            <h2>Ivone Carmen Paim</h2>
            <p>CRP 12/24458</p>
          </div>
        </div>
      </div>
      <img src={ivone} alt="Ivone Psicologa da Família" className={location === '/'? styles.img_ivone : styles.img_ivone_small}/>
    </footer>
    </>
  )
}
