import { BrowserRouter, Route, Routes } from "react-router-dom";
import Agenda from "Pages/Agenda";
import Consultorio from "Pages/Consultorio";
import Convenios from "Pages/Convenios";
import Inicio from "Pages/Inicio";
import Layout from "components/Layout";
import Pagina404 from "Pages/Pagina404";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Inicio />} />
          <Route path="agenda" element={<Agenda />} />
          <Route path="consultorio" element={<Consultorio />} />
          <Route path="convenios" element={<Convenios />} />
        </Route>

        <Route path="*" element={<Pagina404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
