import styles from './AgendaSemanal.module.css'

const AgendaSemanal = ({ data, horarios }) => {

  return (
    <div className={styles.semana}>
      <h3 className={styles.dia}>{data}</h3>
      <div className={styles.horarios}>
        {horarios.map((hora) => (
          <p className={styles.horas}>{hora}</p>
        ))}
      </div>
    </div>
  )
}

export default AgendaSemanal