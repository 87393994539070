import styles from './Consultorio.module.css'

export default function Consultorio() {
  return (
    <div className='content'>
      <h1 className={styles.titulo}>Atendimento na Clínica Ressignificar</h1>
      <p className={styles.texto}>Local: R. Fidêncio de Souza Melo Filho, 169 - Centro, Xanxerê - SC, 89820-000</p>
      <div className={styles.container_img}>
        <img className={styles.img} src="consultorio/ivone-psicologa-consultorio.jpg" alt="Ivone Carmen Paim Psicóloga em seu Consultório" />
        <img className={styles.img} src="consultorio/ivone-psicologa-consultorio-01.jpg" alt="Ivone Carmen Paim Psicóloga em seu Consultório" />
      </div>
    </div>
  )
}
