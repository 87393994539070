import React from 'react'
import styles from './IconePage.module.css'

export default function IconePage() {
  return (
    <div className={styles.icon}>
      <p>Acolhimento Familiar</p>
      <img src="icon-ivone.svg" alt="icone"/>
    </div>
  )
}
