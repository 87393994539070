import styles from './Convenios.module.css'

export default function Convenios() {
  return (
    <div className="content">
      <div className={styles.conteudo}>
        <h1 className={styles.titulo}>Convênios Disponíveis</h1>
        <p className={styles.texto}>Aqui você pode acompanhar os convênios parceiros para agendar sua consulta através de um parceiro conveniado.</p>
        <div className={styles.convenios}>
          <img src="./solumedi.jpg" alt="logo da solumedi" />
          <img src="./tabajara.jpg" alt="logo do tabajara futebol clube" />
        </div>
      </div>
    </div>
  )
}
