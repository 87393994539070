import React from 'react'
import MenuLink from '../MenuLink';
import styles from './Menu.module.css'

export default function Menu() {

  return (
    <header>
      <nav className={styles.menuHeader} >
        <MenuLink to="/">Início</MenuLink>
        <div className={styles.line}></div>
        <MenuLink to="/convenios">Convênios</MenuLink>
        <div className={styles.line}></div>
        <MenuLink to="/agenda">Agenda</MenuLink>
        <div className={styles.line}></div>
        <MenuLink to="/consultorio">Consultorio</MenuLink>
      </nav>
    </header>
  )
}
