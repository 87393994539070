import AgendaSemanal from 'components/AgendaSemanal'
import styles from './Agenda.module.css'
import { useState } from 'react';

const Agenda = () => {
  const [calendario] = useState(['seg', 'ter', 'qua', 'qui', 'sex' ])
  const horarios = ['09h às 10h', '10h às 11h', '14h às 15h', '15h às 16h', '16h às 17h']

  return (
    <div className='content'>
      <div className={styles.agenda}>
        {calendario.map((data, i) => (
          <AgendaSemanal key={i} data={data} horarios={horarios} />
        ))}
      </div>
      <p className={styles.desc}>Clique no botão abaixo para ver a disponíbilidade dos horários e agendar sua consulta</p>
    </div>
  )
}

export default Agenda