import React from 'react'
import Menu from '../Menu'
import { Outlet } from 'react-router-dom'
import IconePage from 'components/IconePage'
import Rodape from 'components/Rodape'

export default function Layout() {
  return (
    <main>
      <IconePage />
      <section>
        <Menu />
        <Outlet />
        <Rodape />
      </section>
    </main>
  )
}
